<template>
  <v-container
    id="contract-quote-form"
    class="add-quote-container"
    :class="sideBarToggled"
    fluid
  >
    <h1 class="page-header">
      {{ modeTitle }} Contract Quote
      <span v-if="contract && contract.contractId">
        #{{ contract.contractId }}
      </span>
      <span v-if="contract.createdOn" class="timestamp">
        <br />
        <span class="label">Created:</span>
        {{ createdOnDisplayText }}
      </span>
      <span v-if="contract.updatedOn" class="timestamp">
        -
        <span class="label">Last Updated:</span>
        {{ updatedOnDisplayText }}
      </span>
    </h1>
    <!-- TODO: Pull this quote from the quote data -->
    <v-form ref="contractQuoteLeadsourceCheckoutpageForm">
      <v-layout class="sheet">
        <v-flex padded xs4>
          <CRInput
            v-if="!isModeAdd"
            id="contract-quote-form-lead-source"
            v-model="contract.leadSource.label"
            label="Lead Source"
            :rules="[(v) => !!v || 'Lead source is required']"
            :disabled="true"
          />
          <CRInput
            v-if="isModeAdd"
            id="contract-quote-form-lead-source"
            v-model="contract.leadSource.label"
            label="Lead Source"
            type="autocomplete"
            :items="suggestedLeadSources"
            item-text="displayName"
            item-key="leadSourceId"
            :return-object="true"
            :search-input.sync="leadSourceSearchTerm"
            :clearable="true"
            clear-icon="replay"
            placeholder="Enter lead source name"
            browser-autocomplete="off"
            :rules="[(v) => !!v || 'Lead source is required']"
            @input="selectLeadSourceFromList"
            @click:clear="resetLeadSource"
          />
          <span
            v-if="op(contract, 'leadSource/id') === null && showLeadSourceError"
            class="error-message"
          >
            Lead Source Required
          </span>
        </v-flex>
        <v-spacer />
        <v-flex padded xs4>
          <CRInput
            v-if="!isModeAdd"
            id="contract-quote-form-checkout-page"
            v-model="contract.checkoutPage.label"
            label="Brand"
            :rules="[(v) => !!v || 'Brand is required']"
            :disabled="true"
          />
          <CRInput
            v-if="isModeAdd"
            id="contract-quote-form-checkout-page"
            v-model="contract.checkoutPage.label"
            required
            type="autocomplete"
            :items="suggestedCheckoutPages"
            item-text="name"
            item-key="companyCheckoutPageId"
            :return-object="true"
            label="Brand"
            :clearable="true"
            clear-icon="replay"
            :search-input.sync="checkoutPageSearchTerm"
            placeholder="Brand"
            browser-autocomplete="off"
            :rules="[(v) => !!v || 'Brand is required']"
            @click:clear="resetCheckoutPage"
            @input="selectCheckoutPageFromList"
          />
        </v-flex>
        <v-spacer />
        <v-flex padded xs2>
          <CRSelect
            id="contract-quote-form-currency-type"
            v-model="contract.currencyType"
            label="Currency"
            :items="['USD', 'CAD']"
            :disabled="isModeView || isModeEdit"
            @input="changeCurrencyType"
          />
        </v-flex>
      </v-layout>
    </v-form>

    <v-flex v-if="!isModeEdit" row class="sheet pa-0">
      <UserSelector
        ref="customerForm"
        v-model="contract.customer"
        :mode="mode"
        :is-customer-selected="isCustomerSelected"
        :show-past-quotes="showPastQuotes"
        @toggle-show-past-quotes="showPastQuotes = !showPastQuotes"
        @set-is-customer-selected="
          (value) => {
            isCustomerSelected = value
          }
        "
        @clear="
          (value) => {
            contract.customer = {}
            contract.customer = value
            isCustomerSelected = false
          }
        "
      />
      <!-- quote.customer.customerId && isCustomerSelected -->
    </v-flex>
    <section v-if="isCustomerSelected && showPastQuotes" class="sheet">
      <div v-if="contract.customer.id" class="results">
        <h1 class="past-quotes-label">Past Quotes</h1>
        <QuotePastQuotesTable
          :key="`customer-quote-table-${contract.customer.id}`"
          :customer="contract.customer"
          @duplicate-trips="duplicateTrips"
        />
      </div>
    </section>

    <section v-show="!showPastQuotes">
      <div class="sheet">
        <v-form ref="contractDetailsForm">
          <v-layout row>
            <v-flex padded xs4>
              <CRInput
                id="contract-quote-form-contract-name"
                v-model="contract.contractName"
                label="Contract Name"
                placeholder="Enter the contract name"
                :rules="[(v) => !!v || 'Contract name is required']"
              />
            </v-flex>
            <v-flex padded xs4>
              <CRInput
                id="contract-quote-form-start-date"
                v-model="contractStartDate"
                type="date"
                label="Start Date"
                placeholder="Start Date"
                :rules="[(v) => !!v || 'Start date is required']"
              />
            </v-flex>
            <v-flex padded xs4>
              <CRInput
                id="contract-quote-form-end-date"
                v-model="contractEndDate"
                type="date"
                label="End Date"
                placeholder="End Date"
                :rules="[(v) => !!v || 'End date is required']"
              />
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex padded xs4>
              <CRSelect
                id="contract-quote-form-billing-frequency"
                v-model="contract.contractBillingFrequency"
                label="Billing Frequency"
                placeholder="Billing Frequency"
                item-text="label"
                return-object
                :items="billingFrequencyOptions"
                :rules="[(v) => !!v || 'Billing frequency is required']"
              />
            </v-flex>
            <v-flex padded xs4>
              <CRSelect
                id="contract-quote-form-billing-method"
                v-model="contract.contractBillingMethod"
                label="Billing Method"
                placeholder="Billing Method"
                item-text="label"
                return-object
                :items="billingMethodOptions"
                :rules="[(v) => !!v || 'Billing method is required']"
              />
            </v-flex>
            <v-flex padded xs4>
              <CRSelect
                id="contract-quote-form-type-of-payment"
                v-model="contract.contractPaymentType"
                label="Type of Payment"
                placeholder="Type of Payment"
                item-text="label"
                return-object
                :items="paymentTypeOptions"
                :rules="[(v) => !!v || 'Type of payment is required']"
              />
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex padded xs4>
              <CRSelect
                id="contract-quote-form-acceptance-terms"
                v-model="contract.termsId"
                label="Acceptance Terms"
                placeholder="Acceptance Terms"
                item-text="label"
                item-value="companyTermsId"
                :items="acceptanceTermsOptions"
                :rules="[(v) => !!v || 'Acceptance terms are required']"
              />
            </v-flex>
            <v-flex padded xs4>
              Allowed Methods of Payment:
              <div class="checkbox-group-container">
                <v-layout
                  v-for="(paymentMethod,
                  index) in contract.contractPaymentMethods"
                  :key="`contract-quote-form-payment-method-checkbox-credit-card-${index}`"
                  row
                >
                  <v-checkbox
                    :id="`contract-quote-form-payment-method-checkbox-credit-card-${index}`"
                    v-model="paymentMethod.value"
                    :label="paymentMethod.label"
                    :rules="paymentMethodRules"
                  />
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row style="margin-top: 24px">
            <v-flex xs12>
              <CRInput
                :value="previousContractNotes"
                multi-line
                label="Contract Notes"
                placeholder="Please add note for the contract here"
                @input="contractNotesChange"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </div>
    </section>

    <section v-show="!showPastQuotes">
      <v-layout class="sheet">
        <v-flex class="checkout-labels">
          <div class="subtitle">Lead Source</div>
          <div class="display-text">
            {{ contract.leadSource.label }}
          </div>
        </v-flex>
        <v-flex class="checkout-labels">
          <div class="subtitle">Brand</div>
          <div class="display-text">
            {{ contract.checkoutPage.label }}
          </div>
        </v-flex>
        <v-btn
          id="contract-quote-form-save-and-send-button"
          :loading="loading"
          class="btn-primaryaction"
          :disabled="loading"
          @click="submit"
        >
          Save Contract Quote
        </v-btn>
      </v-layout>
    </section>
    <v-snackbar v-model="saveContractQuoteError" color="error">
      {{ saveContractQuoteErrorMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'

const CHARTERUP_CHECKOUT_PAGE = {
  id: 4,
  companyCheckoutPageId: 4,
  checkoutPageId: 4,
  key: 'charterup',
  name: 'CharterUP',
  label: 'CharterUP',
  internalName: 'charterup',
}

import op from 'simple-object-path'
import { DateTime } from 'luxon'
import checkoutPages from '@/services/checkoutPages'
import contracts from '@/services/contracts'
import companies from '@/services/companies'
import exchangeRate from '@/services/exchangeRate'
import leadSources from '@/services/leadSources'
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { authComputed } from '@/state/helpers'

import { filter } from '@/utils/filter'
import { currencyFilter } from '@/utils/currency'
import { deepClone } from '@/utils/deepClone'
import * as logger from '@/utils/logger'

import QuotePastQuotesTable from '@/components/QuotePastQuotesTable.vue'
import UserSelector from '@/components/UserSelector.vue'

export default {
  metaInfo() {
    return {
      title: 'Contract Quotes',
    }
  },
  components: {
    UserSelector,
    QuotePastQuotesTable,
  },
  directives: { mask },
  props: {
    id: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      op,
      currencyFilter,
      exchangeRate: 1,
      showAlternativeCurrency: false,
      loading: false,
      validationResults: {},
      selected: [],
      customerAccount: null,
      customerAccountDetail: null,
      alreadyInvitedToCharterUP: false,
      leadSourceSearchTerm: undefined,
      checkoutPageSearchTerm: undefined,
      checkoutPageDebounce: undefined,
      validationKey: undefined,
      saveContractQuoteError: undefined,
      saveContractQuoteErrorMessage: undefined,
      pastQuotes: [],
      isCustomerSelected: false,
      isLeadSourceSelected: false,
      isCheckoutPageSelected: false,
      showCheckoutPageError: false,
      showLeadSourceError: false,
      suggestedLeadSources: [],
      suggestedCheckoutPages: [],
      showPastQuotes: false,
      hasCustomerFieldInputs: false,
      contract: {
        contractName: null,
        contractId: null,
        createdOn: null,
        updatedOn: null,
        leadSource: { id: null, key: null, label: null },
        checkoutPage: { id: null, key: null, name: null },
        contractStartDate: null,
        contractEndDate: null,
        contractBillingFrequency: null,
        contractBillingMethod: null,
        contractPaymentType: null,
        termsId: null,
        contractPaymentMethods: [
          {
            id: 1,
            key: 'credit_card',
            label: 'Credit Card',
            description: null,
            value: false,
          },
          {
            id: 2,
            key: 'ach',
            label: 'ACH',
            description: null,
            value: false,
          },
          {
            id: 3,
            key: 'check',
            label: 'Check',
            description: null,
            value: false,
          },
        ],
        contractNotes: null,
        contractNotesList: [],
        customer: {
          id: null,
          firstName: '',
          lastName: '',
          organization: '',
          bookings: null,
          email: '',
          customerAccountId: null,
        },
        currencyType: 'USD',
      },
      billingFrequencyOptions: null,
      billingMethodOptions: null,
      paymentTypeOptions: null,
      acceptanceTermsOptions: [],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      selectedCustomerAccount: 'quotes/selectedCustomerAccount',
    }),
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    contractStartDate: {
      get: function () {
        return this.formatDateForForm(this.contract.contractStartDate)
      },
      set: function (date) {
        this.contract.contractStartDate = this.formatDateForServer(date)
      },
    },
    contractEndDate: {
      get: function () {
        return this.formatDateForForm(this.contract.contractEndDate)
      },
      // setter
      set: function (date) {
        this.contract.contractEndDate = this.formatDateForServer(date)
      },
    },
    previousContractNotes() {
      return (
        this.contract.contractNotesList?.[0]?.note ||
        this.contract.contractNotes
      )
    },
    sideBarToggled() {
      const state = this.getSideBar()
      if (state) {
        return 'why'
      }
      return 'not'
    },
    selectedCustomer: {
      get() {
        return this.contract.customer
      },
      set(value) {
        this.contract.customer = value
      },
    },
    modeTitle() {
      let modeTitleString = 'Add New'
      if (this.isModeView) {
        modeTitleString = 'View'
      }
      if (this.isModeEdit) {
        modeTitleString = 'Edit'
      }
      return modeTitleString
    },
    createdOnDisplayText() {
      return this.formatDateTimeForDisplay(this.contract.createdOn)
    },
    updatedOnDisplayText() {
      return this.formatDateTimeForDisplay(this.contract.updatedOn)
    },
    paymentMethodRules() {
      return [
        this.contract.contractPaymentMethods[0].value ||
          this.contract.contractPaymentMethods[1].value ||
          this.contract.contractPaymentMethods[2].value ||
          'Must select at least one method of payment',
      ]
    }
  },
  watch: {
    async leadSourceSearchTerm(value) {
      await this.searchLeadSourceList(value)
    },
    async checkoutPageSearchTerm(value) {
      await this.searchCheckoutPagesList(value)
    },
    selectedCustomerAccount(newVal) {
      this.customerAccount = newVal
    },
    async 'contract.customer'(newCustomer) {
      const customerAccountId = newCustomer.customerAccountId

      if (customerAccountId) {
        this.customerAccountDetail = (await customerAccounts.getCustomerAccount(this.contract.customer.customerAccountId)).data
      } else {
        this.customerAccountDetail = null
      }
    },
    customerAccountDetail(detail) {
      if (!detail) {
        return
      }

      const billingFrequencyTypeId = detail.billingFrequencyTypeId

      if (!this.contract.contractBillingFrequency) {
        this.contract.contractBillingFrequency = this.billingFrequencyOptions?.find( value => value.id === billingFrequencyTypeId)
      }
    }
  },
  async mounted() {
    if (this.isModeEdit) {
      this.loadContractForEdit()
    }
    this.searchCheckoutPagesList('')
    this.getTypesAndOptions()
  },
  methods: {
    ...mapGetters({
      getSideBar: 'dashboard/getSideBar',
    }),
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    async loadContractForEdit() {
      const contractResponse = await contracts.getContract(this.id)
      const contract = contractResponse.data
      this.contract.contractName = contract.contractName
      this.contract.contractStartDate = contract.contractStartDate
      this.contract.contractEndDate = contract.contractEndDate
      this.contract.contractBillingFrequency = contract.contractBillingFrequency
      this.contract.contractBillingMethod = contract.contractBillingMethod
      this.contract.contractPaymentType = contract.contractPaymentType
      this.contract.termsId = contract.companyTerm.companyTermId
      if (contract.checkoutPage == null) {
        this.contract.checkoutPage = CHARTERUP_CHECKOUT_PAGE
      } else {
        this.contract.checkoutPage = contract.checkoutPage
      }
      this.contract.leadSource = contract.leadSourceType
      // this.contract.contractNotes = contract.contractNotes    uncomment this once notes are added
      for (const paymentMethod of contract.contractPaymentMethods) {
        this.contract.contractPaymentMethods.map((p) => {
          if (p.id === paymentMethod.id) {
            p.value = true
          }
        })
      }
    },
    async getTypesAndOptions() {
      const { data: paymentTypes } = await this.$store.dispatch(
        'types/getPaymentTypes'
      )
      this.paymentTypeOptions = paymentTypes.splice(0, 3)
      const {
        data: billingMethodTypes,
      } = await contracts.getBillingMethodTypes()
      this.billingMethodOptions = billingMethodTypes
      const {
        data: billingFrequencyTypes,
      } = await contracts.getBillingFrequencyTypes()
      this.billingFrequencyOptions = billingFrequencyTypes
      const termsResponse = await companies.getCompanyTerms()
      this.acceptanceTermsOptions = termsResponse.data.resultList
    },
    contractNotesChange(value) {
      this.contract.contractNotes = value
      if (this.contract.contractNotesList?.[0]) {
        this.contract.contractNotesList[0].note = value
      } else {
        this.contract.contractNotesList = []
        this.contract.contractNotesList.push({ note: value })
      }
    },
    customerFieldsUpdated(/* event */) {},
    async searchLeadSourceList(value) {
      if (typeof value !== 'string' || value.length === 0) {
        return
      }

      const filterObject = {
        column: {
          _t_id: 'lead_source_search_id',
          prop: 'displayName',
          filterType: 'contains',
          filterAsIs: true,
        },
        value,
      }
      const leadSourceFilter = filter()
      const parentFilter = leadSourceFilter.createParent('and')
      leadSourceFilter.add(parentFilter, filterObject)
      if (this.leadSourceDebounce) {
        clearTimeout(this.leadSourceDebounce)
      }
      this.leadSourceDebounce = setTimeout(async () => {
        const params = {
          filters: leadSourceFilter.asQueryParams(),
          pageSize: 5,
        }
        const matchedLeadSources = await leadSources.getLeadSources(params)
        const suggestedLeadSources = matchedLeadSources?.data?.resultList || []
        this.suggestedLeadSources = suggestedLeadSources.filter(
          (suggestedLeadSource) => suggestedLeadSource.internalName
        )
      }, 500)
    },
    selectLeadSourceFromList(leadSource) {
      if (!leadSource) {
        return
      }
      if (!leadSource.internalName) {
        return false
      }
      const { company } = this.currentUser
      this.contract.leadSource.id = leadSource.leadSourceId
      this.contract.leadSource.label = leadSource.displayName
      this.contract.leadSource.key = leadSource.internalName
      this.contract.leadSource.partnerCompanyId = leadSource.partnerCompanyId
      this.contract.leadSource.company = { id: company.companyId }
      this.isLeadSourceSelected = true
    },
    async searchCheckoutPagesList(value) {
      const filterObject = {
        column: {
          _t_id: 'checkout_pages_search_id',
          prop: 'name',
          filterType: 'contains',
        },
      }
      filterObject.value = value
      if (typeof value === 'string') {
        return
      }

      const checkoutPageFilter = filter()
      const parentFilter = checkoutPageFilter.createParent('and')
      checkoutPageFilter.add(parentFilter, filterObject)
      if (this.checkoutPageDebounce) {
        clearTimeout(this.checkoutPageDebounce)
      }
      this.checkoutPageDebounce = setTimeout(async () => {
        const params = {
          filters: checkoutPageFilter.asQueryParams(),
          pageSize: 5,
        }
        const matchedCheckoutPages = await checkoutPages.getCheckoutPages(
          params
        )
        this.suggestedCheckoutPages = matchedCheckoutPages?.data?.resultList
          ?.length
          ? matchedCheckoutPages.data.resultList
          : [CHARTERUP_CHECKOUT_PAGE]
      }, 500)
    },
    selectCheckoutPageFromList(checkoutPage) {
      if (!checkoutPage) {
        return
      }
      this.contract.checkoutPage.id = checkoutPage.companyCheckoutPageId
      this.contract.checkoutPage.label = checkoutPage.name
      this.contract.checkoutPage.key = checkoutPage.internalName

      this.isCheckoutPageSelected = true
    },
    resetLeadSource() {
      this.contract.leadSource.id = null
      this.contract.leadSource.label = null
      this.contract.leadSource.key = null
      this.isLeadSourceSelected = false
    },
    resetCheckoutPage() {
      this.contract.checkoutPage.id = null
      this.contract.checkoutPage.label = null
      this.contract.checkoutPage.key = null
      this.isCheckoutPageSelected = false
    },
    async changeCurrencyType(currencyType) {
      if (currencyType !== 'USD') {
        this.showAlternativeCurrency = true
        const exchangeRateData = await exchangeRate
          .getExchangeRate()
          .catch((e) => e)
        this.exchangeRate = exchangeRateData?.data?.rates?.[currencyType]
      }
    },
    formatDateTimeForDisplay(dateTime) {
      return DateTime.fromISO(dateTime, {
        zone: this.currentUser.company.address.timeZone,
      }).toLocaleString(DateTime.DATETIME_SHORT)
    },
    formatDateForServer(date) {
      return DateTime.fromISO(date, {
        zone: this.currentUser.company.address.timeZone,
      }).toISO()
    },
    formatDateForForm(date) {
      return DateTime.fromISO(date, {
        zone: this.currentUser.company.address.timeZone,
      }).toISODate()
    },
    async submit() {
      if (this.isModeAdd) {
        const isLeadsourceCheckoutPageValid = this.$refs.contractQuoteLeadsourceCheckoutpageForm.validate()
        const isCustomerValid =
          this.$refs.customerForm.validate() && this.isCustomerSelected
        const areContractDetailsValid = this.$refs.contractDetailsForm.validate()
        const allValid =
          isLeadsourceCheckoutPageValid &&
          isCustomerValid &&
          areContractDetailsValid

        if (allValid) {
          const payload = this.processPayload()
          this.loading = true
          try {
            const response = await contracts.createContractQuote(payload)
            const quoteId = response.data.quote.quoteId

            this.$router.push({
              name: `contracts.view`,
              params: { id: quoteId },
            })
            this.showAlert({
              message: 'Contract quote saved successfully.',
              type: 'success',
            })
          } catch (err) {
            this.showAlert({
              message: 'Error creating contract quote, please try again.',
              type: 'error',
            })
            this.loading = false
            logger.error(err)
          }
        }
      } else if (this.isModeEdit) {
        const areContractDetailsValid = this.$refs.contractDetailsForm.validate()

        if (areContractDetailsValid) {
          let payload = {}
          const contractClone = deepClone(this.contract)
          delete contractClone.contractPaymentMethods
          contractClone.contractPaymentMethods = []
          contractClone.contractId = this.id
          this.contract.contractPaymentMethods.forEach((paymentMethod) => {
            if (paymentMethod.value) {
              contractClone.contractPaymentMethods.push(paymentMethod.id)
            }
          })

          Object.entries(contractClone).forEach(([key, value]) => {
            payload[key] = value
          })

          this.loading = true
          try {
            const response = await contracts.editContract(payload)
            const quoteId = response.data.quote.quoteId

            this.$router.push({
              name: `contracts.view`,
              params: { id: quoteId },
            })
            this.showAlert({
              message: 'Contract quote edited successfully.',
              type: 'success',
            })
          } catch (err) {
            this.showAlert({
              message: 'Error editing contract quote, please try again.',
              type: 'error',
            })
            this.loading = false
            logger.error(err)
          }
        }
      }
    },
    processPayload() {
      let contractClone = deepClone(this.contract)
      const payload = {
        customerId: this.contract.customer.id,
        companyId: this.currentUser.companyId,
        checkoutPageId: this.contract.checkoutPage.id,
        leadSourceTypeId: this.contract.leadSource.id,
        decisionDate: null,
        expirationDate: null,
        preferred: null,
        randomMultiplier: null,
        allowEcheckout: true, //TODO determine how to set this
        attachPdfToEmail: true, //TODO determine how to set this
        contractIsOpen: true,
        quoteType: 1,
        contractPaymentMethods: [],
      }

      contractClone.contractPaymentMethods.forEach((paymentMethod) => {
        if (paymentMethod.value) {
          payload.contractPaymentMethods.push(paymentMethod.id)
        }
      })

      delete contractClone.contractPaymentMethods
      delete contractClone.leadSource
      delete contractClone.contractId
      delete contractClone.createdOn
      delete contractClone.updatedOn
      delete contractClone.customer
      delete contractClone.checkoutPage

      Object.entries(contractClone).forEach(([key, value]) => {
        payload[key] = value
      })
      return payload
    },
  },
}
</script>

<style lang="scss" scoped>
.why {
  margin-top: -40px;
}

.not {
  margin-top: -10px;
  h1 {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1140px) {
  .why {
    margin-top: -25px;
    h1 {
      margin-left: 33px;
    }
  }
}

.error-message {
  margin: 10px;
  font-size: 12px;
  color: $error !important;
}

.past-quotes-label {
  font-size: 23px;
}

.timestamp {
  font-size: 12px;

  .label {
    font-weight: bold;
  }
}

.subtitle {
  color: $gray-medium-light;
}

.display-text {
  padding-top: 8px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.35em;
  letter-spacing: normal;
}

.checkbox-group-container {
  width: 100%;
  float: left;
  .v-input--selection-controls {
    &.v-input--checkbox {
      margin-top: 0;
    }
    ::v-deep .v-input__control {
      .v-messages {
        .v-messages__wrapper {
          .v-messages__message {
            margin-left: 125px !important;
            margin-top: -26px !important;
          }
        }
      }
    }
    ::v-deep &:not(.v-input--hide-details) .v-input__slot {
      margin-bottom: 0;
    }
  }
  label,
  .v-input--checkbox {
    float: left;
    height: 28px;
  }
  ::v-deep .v-input--selection-controls__ripple {
    .v-ripple__container {
      display: none;
    }
  }
  ::v-deep label.v-label {
    width: 100%;
    font-size: 14px;
    margin-right: 16px;
    white-space: nowrap;
  }
}
</style>
